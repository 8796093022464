import { template as template_c94ed0637b0c4cc187de40bdff705c69 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c94ed0637b0c4cc187de40bdff705c69(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
